export class Template {
  static setup() {
    // Only init on template pages
    if ($('.template_images_holder').length == 0) {
      return;
    }
    var template_image_image_id_field = null;
    var template_image_img_field = null;
    var init_inline_froala = function (jq_elements) {
      jq_elements.each(function(index, el) {
        new Froalaable($(el), 'minimum');
      });
    };
    // $('#modal-images-holder').modal({
    //     keyboard: true,
    //     backdrop: true
    // })
    // $('#modal-images-holder').modal("hide");

    $('.template_images_holder').on('click', '.btn-select-image', function(event) {
      event.preventDefault();
      var el, store_image_id_field, image_img_field;
      el = $(event.target);
      template_image_image_id_field = null;
      store_image_id_field = el.closest('.template_image_fields').find('.image_id_field');
      image_img_field      = el.closest('.template_image_fields').find('.image_img_field');
      if(store_image_id_field.length > 0) {
        template_image_image_id_field = store_image_id_field;
        template_image_img_field      = image_img_field;
        $('#modal-images-holder').modal('show');
      } else {
        alert('Cannot setup image fields (Not found)');
      };
    });

    $('.template_images_holder').on('click', '.btn-remove-image', function(event) {
      event.preventDefault();
      var el, destroy_image_field;
      el = $(event.target);
      // var store_image_id_field = el.closest(".template_image_fields").find(".image_id_field");
      // var image_img_field      = el.closest(".template_image_fields").find(".image_img_field");
      destroy_image_field = el.closest('.template_image_fields').find('.destroy_field');
      if(destroy_image_field.length > 0) {
        destroy_image_field.val('true');
        el.closest('.template_image_fields').hide();
      } else {
        alert('Cannot remove image from template.');
      };
    });

    $('#modal-images-holder').on('click', '.btn-choose-image', function(event) {
      event.preventDefault();
      var el;
      el = $(event.target);
      if (template_image_image_id_field != null) {
        template_image_image_id_field.val(el.data('imageId'));
      } else {
        alert('Cannot set id for image as template_image_image_id_field is null.')
      }
      if (template_image_img_field != null) {
        template_image_img_field.attr('src', el.data('imageUrl'));
      } else {
        alert('Cannot set src for image as template_image_img_field is null.')
      };
      $('#modal-images-holder').modal('hide');
    });

    $('form').submit(function (event) {
      $('.remove-before-post-field').remove();
    })
  };
};
