// Require Editor JS files.
// import FroalaEditor from 'froala-editor/js/froala_editor.min.js';
// import 'froala-editor/plugins/align.min.js';

// import 'codemirror';
// import 'froala-editor/plugins/align.min.js';
// let FroalaEditor =  require('froala-editor/js/froala_editor.min.js');
// let FroalaEditor = require('froala-editor/froala-4.1.4-patched.js');
import FroalaEditor from 'froala4/froala-4.1.4-patched.js';
import 'froala4/plugins/align.min.js';

// // import 'froala4/plugins/code_view.min.js';
import 'froala4/plugins/colors.min.js';
import 'froala4/plugins/draggable.min.js';
import 'froala4/plugins/emoticons.min.js';
import 'froala4/plugins/entities.min.js';
// import 'froala4/plugins/file.min.js';
import 'froala4/plugins/font_family.min.js';
// import 'froala/plugins/font_size.2.9.5-patched';
// require plugins/font_size.min.js
import 'froala4/plugins/char_counter.min.js';
import 'froala4/plugins/code_beautifier.min.js';
import 'froala4/plugins/code_view.min.js';
// import 'froala/plugins/code_view.2.9.5-patched';

// import 'froala4/plugins/fullscreen.min.js';
import 'froala4/plugins/image.min.js';
import 'froala4/plugins/image_manager-patched.min.js';

// import 'froala/plugins/image_manager.2.9.5-patched';

import 'froala4/plugins/inline_style.min.js';
import 'froala4/plugins/line_breaker.min.js';
import 'froala4/plugins/link.min.js';
import 'froala4/plugins/lists.min.js';
import 'froala4/plugins/paragraph_format.min.js';
import 'froala4/plugins/paragraph_style.min.js';
import 'froala4/plugins/quote.min.js';
import 'froala4/plugins/quick_insert.min.js';
import 'froala4/plugins/save.min.js';
import 'froala4/plugins/special_characters.min.js';
import 'froala4/plugins/table.min.js';
import 'froala4/plugins/url.min.js';
// import 'froala4/plugins/video.min.js';
import 'froala4/plugins/word_paste.min.js';

import 'froala4/third_party/font_awesome.min.js';

import 'froala4/languages/nb.js';
// import PNotify from 'pnotify/lib/es/PNotify.js';
// PNotify.defaults.styling  = 'bootstrap4';

// TODO: Add custom colors
// https://www.froala.com/wysiwyg-editor/docs/examples/colors

export class Froalaable {
  constructor (element, type, extra_opts= {}, froala_save = false) {
    this._el = element;
    this._type = type;
    this._content_changed = false;
    this._froala_save = froala_save;
    this.init(extra_opts, froala_save);
  }

  init(extra_opts, froala_save) {
    let opts = this.opts(extra_opts, froala_save);
    // this.editor = new FroalaEditor(this._el, {
      // pluginsEnabled: ['align'],
      // toolbarButtons: ['bold', 'align', 'paragraph', 'fontSize'],
    // });
    let that = this;
    new FroalaEditor(this._el, opts,
      function() {
        that.editor = this;
        that.addAuthenticationTokens(froala_save);
        that.enableCallBacks(froala_save);
      });

    // this.editor = new FroalaEditor(this._el, opts);
    // this.editor = new FroalaEditor(this._el);
    // TODO Fix callbacks
    // this.addAuthenticationTokens(froala_save);
    // this.enableCallBacks(froala_save);
    // setTimeout(this.removeUnlicensedMsg.bind(this), 50);
  }

  removeUnlicensedMsg() {
    Array.from(this._el.querySelectorAll('a')).forEach(function(el) {
      if (el.textContent.includes("Unlicensed Froala Editor") || el.textContent.includes("Unlicensed copy of the Froala Editor")) {
        el.parentNode.remove();
      }
    });

    Array.from(this._el.querySelectorAll('span')).forEach(function(el) {
      if (el.textContent.includes("Type something")) {
        el.style.marginTop = '0px';
      }
    });
  }

  save() {
    if (this._froala_save && this.isDirty()) {
      this.editor.froalaEditor('save.save');
    }
  }

  destroy() {
    this.editor.froalaEditor('destroy');
    this.removeCallBacks();
    this.editor = undefined;
  }

  addAuthenticationTokens(froala_save) {
    let imageUploadParams;
    let saveParams;
    let authenticity_token = this.authenticityToken();
    if (froala_save === true) {
      saveParams = this.editor.opts.saveParams;
      saveParams.authenticity_token = authenticity_token;
      this.editor.opts.saveParams = saveParams;
    }

    imageUploadParams = this.editor.opts.imageUploadParams;
    // find template id

    if ($('input#image_template_id').length > 0) {
      imageUploadParams.template_id = $('input#image_template_id').val();
    }

    imageUploadParams.authenticity_token = authenticity_token;
    this.editor.opts.imageUploadParams = imageUploadParams;
  }

  authenticityToken (){
    return $('meta[name="csrf-token"]').attr('content');
  }

  commonOpts() {
    return {
      iconsTemplate: 'font_awesome_5',
      imageStyles:   {
        'rounded':        'Rounded',
        'img-thumbnail':  'Thumbnail/border',
        'img-fluid':      'Responsive'
      },

      imageResizeWithPercent: true,

      // To avoid ms-word css...
      pastePlain: true,

      // Set the image upload parameter.
      imageUploadParam: 'froala_image_file',

      // Set the image upload URL.
      imageUploadURL: '/froala_images.json',

      // TODO: Might not work anymore, since
      // pastedImagesUploadURL: '/froala_images/pasted.json',

      // Additional upload params.
      imageUploadParams: { },

      imageManagerLoadURL: '/froala_images.json',

      // Images have no delete url, so this is safe.
      // If deleting is implemented through froala, this must be true
      imageDefaultWidth: 0,

      // Block styles
      // TODO: FIX THIS
      // New styling works differently
      paragraphStyles: {
        'lead':         'lead',
        'text-muted':   'muted',
        'text-primary': 'primary',
        'text-success': 'success',
        'text-info':    'info',
        'text-warning': 'warning',
        'text-danger':  'danger'
      },
      // codeMirror: true
    }
  }
  opts(extra_opts, froala_save) {
    let opts = this.commonOpts();
    switch(this._type) {
      case 'full':
        opts = jQuery.extend(opts, this.fullOpts);
        break;
      case 'medium-no-image':
        opts = jQuery.extend(opts, this.mediumNoImageOpts);
        break;
      case 'medium':
        opts = jQuery.extend(opts, this.mediumOpts);
        break;
      case 'minimum':
        opts = this.minimumOpts;
        break;

      case 'none':
        opts = this.noneOpts;
        break;
      default:
        opts = jQuery.extend(opts, this.fullOpts);
        break;
    }

    if (froala_save) {
      jQuery.extend(opts, {
        // Must set save interval to 0, or double save is performed on single html editable...
        // WEIRD!
        saveInterval: 0,
        saveMethod:   'PUT',
        saveURL:      this._$el.attr('data-url'),
        saveParams:   {
          column:     this._$el.attr('data-column'),
          froala:     1, // for testing for 'froala' data on backend
          plain_save: this._type === 'none' ? 1 : 0
        }
      });
    } else {
      jQuery.extend(opts, {
        saveInterval: 0
      });
    }
    jQuery.extend(opts, extra_opts);
    return opts;
  }

  getHTML () {
    return this.editor.froalaEditor('html.get', false, false);
  }

  // Editor configs
  get fullOpts () {
    return {
      toolbarInline: false,
      alwaysVisible: false,
      toolbarSticky: true,
      // autosave: true, // should be true when implementation works!
      // autsaveInterval: 10000, // 10 seconds should be ok
      pluginsEnabled: [
        'align', 'codeBeautifier', 'codeView', 'colors', 'draggable',
        'entities', 'fontAwesome', 'fontSize', 'fullscreen', 'image',
        'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists', 'paragraphFormat',
        'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'
      ],

      // pluginsDisabled: [
      //   'embedly',
      //   'emoticons',
      //   'file',
      //   'imageTUI',
      //   'fontFamily',
      //   'charCounter',
      // ]

      toolbarButtons:
      [
        'bold',
        'italic',
        'underline',
        'paragraphFormat',
        'paragraphStyle',
        // 'fontFamily',
        'fontSize',
        '|',
        'color',
        'alignLeft',
        'alignCenter',
        'alignRight',
        'alignJustify',
        'formatOL',
        'formatUL',
        'outdent',
        'indent',
        '|',
        'undo',
        'redo',
        '-',
        // 'selectAll',
        'insertLink',
        'insertImage',
        'insertVideo',
        'insertTable',
        'insertHR',
        'clearFormatting',
        //'save',
        '|',
        'emoticons',
        'quote',
        '|',
        'html',
        'fullscreen'
      ],
    };
    // TODO: CUSTOM BUTTONS FOR v2
    //
    // customButtons:
    // {
    //   insertSpanBtn:
    //   {
    //     title: 'Insert span',
    //
    //     icon:
    //     {
    //       type:  'txt',
    //       value: '<span class="froala-text-btn">span</span>'
    //     },
    //     callback: function () {
    //       // Insert HTML.
    //       this.insertHTML('<span>Span</span>');
    //       // Sync.
    //       this.sync();
    //     }
    //   },
    //   calloutBtn:
    //   {
    //     title: 'Insert bootstrap callout',
    //
    //     icon:
    //     {
    //       type:  'txt',
    //       value: '<span class="froala-text-btn">callout</span>'
    //     },
    //     callback: function () {
    //       // Insert HTML.
    //       this.insertHTML('<div class="bs-callout bs-callout-info">callout<h3>header</h3><p>paragraph</p></div>');
    //       // Sync.
    //       this.sync();
    //     }
    //   }
    // }

  }

  get mediumOpts () {
    return {
      toolbarVisibleWithoutSelection: false,

      toolbarInline: true,
      toolbarSticky: true,

      pluginsEnabled: [
        'align', 'codeBeautifier', 'codeView', 'colors', 'draggable',
        'entities', 'fontAwesome', 'fontSize', 'fullscreen', 'image',
        'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists', 'paragraphFormat',
        'paragraphStyle', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'
      ],

      toolbarButtons:
      [
        'bold',
        'italic',
        'underline',
        'paragraphFormat',
        'paragraphStyle',
        'fontSize',
        'color',
        'alignLeft',
        'alignCenter',
        'alignRight',
        'alignJustify',
        'formatOL',
        'formatUL',
        'outdent',
        'indent',
        '|',
        'insertLink',
        'insertImage',
        'clearFormatting',
        'undo',
        'redo',
        // 'save',
        'html'
      ],
    };
  }

  get mediumNoImageOpts () {
    return {
      toolbarVisibleWithoutSelection: false,

      toolbarInline: false,
      toolbarSticky: true,

      pluginsEnabled: [
        'align', 'codeBeautifier', 'codeView', 'colors', 'draggable',
        'entities', 'fontAwesome', 'fontSize', 'fullscreen', 'inlineStyle', 'inlineClass',
        'lineBreaker', 'lineHeight', 'link', 'lists', 'paragraphFormat',
        'paragraphStyle', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'
      ],

      toolbarButtons:
      [
        'bold',
        'italic',
        'underline',
        'paragraphFormat',
        'paragraphStyle',
        'color',
        'alignLeft',
        'alignCenter',
        'alignRight',
        'alignJustify',
        'formatOL',
        'formatUL',
        'outdent',
        'indent',
        '|',
        'insertLink',
        'clearFormatting',
        'undo',
        'redo',
        // 'save',
        'html'
      ],
    };
  }

  get minimumOpts () {
    return {
      toolbarVisibleWithoutSelection: false,

      toolbarInline: true,
      toolbarSticky: false,

      pluginsEnabled: [
        'align', 'codeBeautifier', 'codeView', 'colors', 'draggable',
        'entities', 'fontAwesome', 'fontSize', 'fullscreen', 'inlineStyle', 'inlineClass',
        'lineBreaker', 'lineHeight', 'link', 'lists', 'paragraphFormat',
        'paragraphStyle', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'
      ],

      toolbarButtons:
      [
        'bold',
        'italic',
        'underline',
        'color',
        '|',
        'clearFormatting',
        'undo',
        'redo',
        // 'save',
        'html'
      ],
    };
  }

  get noneOpts () {
    return {
      toolbarVisibleWithoutSelection: false,

      pluginsEnabled: [
        'align', 'codeBeautifier', 'codeView', 'colors', 'draggable',
        'entities', 'fontSize', 'fullscreen', 'inlineStyle', 'inlineClass',
        'lineBreaker', 'lineHeight', 'link', 'lists', 'paragraphFormat',
        'paragraphStyle', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'
      ],

      toolbarInline:  true,
      toolbarSticky:  false,
      // autosave: true, // should be true when implementation works!
      // autsaveInterval: 10000, // 10 seconds should be ok
      imagePaste:     false,
      blockStyles:    { },
      toolbarButtons: [ ]
    };
  }


  // Set callbacks
  enableCallBacks(froala_save) {
    this.editor.events.on('froalaEditor.image.error', $.proxy(this.imageError, this));
    this.editor.events.on('froalaEditor.imageManager.imagesLoaded', $.proxy(this.imagesLoaded, this));
    this.editor.events.on('froalaEditor.imageManager.loadError', $.proxy(this.imageLoadError, this));
    this.editor.events.on('froalaEditor.contentChanged', $.proxy(this.contentChanged, this));
    if(froala_save === true) {
      this.editor.events.on('editable.save.error', function (/* e, editor, error */) {
        PNotify.error({
          title: 'Oida',
          text:  'Klarte ikke å lagre det du gjorde Klipp/lim det inn i notisblokk, så du ikke mister det!!',
          delay: 5000
        });
      });
    }
  }

  removeCallBacks() {
    this.editor.events.off('froalaEditor.image.error');
    this.editor.events.off('froalaEditor.imageManager.imagesLoaded');
    this.editor.events.off('froalaEditor.imageManager.loadError');
    this.editor.events.off('froalaEditor.save.error');
    this.editor.events.off('froalaEditor.contentChanged');
  }

  // Content changed cb
  contentChanged() {
    this._content_changed = true;
  }

  isDirty() {
    return this._content_changed;
  }

  // Froala image callbacks

  imagesLoaded (/* e, editor, data */) {
    // Remove delete links
    $('a.f-delete-img').remove();
    // console.log ('Images have been loaded.');
  }

  imagesLoadError (e, editor, error) {
    console.log('Froala Error loading images: Code:' + error.code);
    // // Custom error message returned from the server.
    // if (error.code === 0) {  }

    // // Bad link. One of the returned image links cannot be loaded.
    // else if (error.code === 1) {  }

    // // Error during HTTP request to load images.
    // else if (error.code === 2) {  }

    // // Missing imagesLoadURL option.
    // else if (error.code === 3) {  }

    // // Parsing response failed.
    // else if (error.code === 4) {  }
  }

  imageError (e, editor, error) {
    // Custom error message returned from the server.
    console.log('Froala imageError:');
    console.log(error);
    // if (error.code === 0) {  }

    // // Bad link.
    // else if (error.code === 1) {  }

    // // No link in upload response.
    // else if (error.code === 2) {  }

    // // Error during image upload.
    // else if (error.code === 3) {  }

    // // Parsing response failed.
    // else if (error.code === 4) {  }

    // // Image too large.
    // else if (error.code === 5) {  }

    // // Invalid image type.
    // else if (error.code === 6) {  }

    // // Image can be uploaded only to same domain in IE 8 and IE 9.
    // else if (error.code === 7) {  }
  }
  // afterRemoveImage: function (e, editor, $img) {
  //   console.log('Froala After remove images');
  //   // Set the image source to the image delete params.
  //   editor.options.imageDeleteParams = {src: $img.attr('src')};

  // // Catch image delete successful.
  // imageDeleteSuccess: function (e, editor, data) {
  //   console.log('Froala After delete success');
  // },

  // // Catch image delete error.
  // imageDeleteError: function (e, editor, error) {
  //   console.log('Froala imageDeleteError: Code:' + error.code);
  // },
}
