import { Alert, Dropdown, Tooltip, Collapse, Popover, Toast } from 'bootstrap';
export class Bootstrap5 {
  static setup(){
    Bootstrap5.alerts()
    Bootstrap5.dropdowns()
    Bootstrap5.tooltips();
    // Bootstrap5.collapse();
  }

  // static collapse() {
  //   var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
  //   collapseElementList.map(function (collapseEl) {
  //     return new Collapse(collapseEl)
  //   })
  // }

  static alerts() {
    let alertList = document.querySelectorAll('.alert')
    let alerts = [].slice.call(alertList).map(function (element) {
      return new Alert(element)
    })
  }

  static dropdowns() {
    var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
    dropdownElementList.map(function (dropdownToggleEl) {
      return new Dropdown(dropdownToggleEl)
    })
  }


  static tooltips () {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
    })
  }
}
