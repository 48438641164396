import Clipboard from 'clipboard'
import Cookies from 'js-cookie'
import { Tooltip } from 'bootstrap';

export class IntranetTools {

  static setup(){
    IntranetTools.setupStandardContacts();
    IntranetTools.setupClipboardElements();
    IntranetTools.setupAdvancedFields();
    IntranetTools.setupTools();
    IntranetTools.setupDynamicFieldButtons();
  }

  static setupStandardContacts() {
    $('.standard-contact-table').on('click', '.select-standard-contact', function(event) {
      event.preventDefault();
      var hidden_fields_holder = $(event.target).closest('.standard-contact-holder').find('.hiddenfields');
      if (hidden_fields_holder.length == 0) {
        console.log('Cannot find fields');
        return;
      }
      var fields = ['skole', 'kontaktperson', 'adresse', 'postnummer', 'poststed', 'email'];
      var set_field;
      var get_field;
      for (var i = 0; i < fields.length; i++) {
        set_field = $('#datum_' + fields[i]);
        get_field = hidden_fields_holder.find('#sc_' + fields[i]);
        if ((set_field.length > 0) && (get_field.length > 0)) {
          set_field.val(get_field.val());
        }
      }
    });
  };

  static setupClipboardElements() {
    if ($('.js-copy-element').length > 0) {
      new Clipboard('.js-copy-element');
      // $('.js-copy-element').on('mouseover', function(element, args) {
      //   if ($(this).hasClass('js-tooltip-manual')) {
      //     Tooltip.getOrCreateInstance($(this)[0]).show();
      //     // $(this).tooltip('show');
      //   }
      // });
      // $('.js-copy-element').on('mouseout', function(element, args) {
      //   if ($(this).hasClass('js-tooltip-manual')) {
      //     Tooltip.getOrCreateInstance($(this)[0]).hide();
      //   }
      // });
    };
  };

  static setupAdvancedFields() {
    $('.toggle_advanced_fields').on('click', function(event) {
      var adv_fields, el;
      el = $(event.delegateTarget);
      adv_fields = $('.advanced_fields');
      if(adv_fields.hasClass('d-none')) {
        adv_fields.removeClass('d-none');
        el.html('<i class="fal fa-eye-slash"></i> Skjul ekstra felter')
        Cookies.set('advanced_fields_visible', true);
      } else {
        adv_fields.addClass('d-none');
        el.html('<i class="fal fa-eye"></i> Vis ekstra felter')
        Cookies.set('advanced_fields_visible', false);
      }
    });
  };

  static setupTools() {
    $('.toggle_tools').on('click', function(event) {
      var extra_tools, el;
      el = $(event.delegateTarget);
      extra_tools = $('.extra_tools');
      if(el.hasClass('tools_hidden')) {
        el.removeClass('tools_hidden').addClass('tools_visible');
        extra_tools.removeClass('d-none');
        el.html('<i class="fal fa-eye-slash"></i> Skjul ekstra verktøy')
      } else {
        el.removeClass('tools_visible').addClass('tools_hidden');
        extra_tools.addClass('d-none');
        el.html('<i class="fal fa-eye"></i> Vis ekstra verktøy')
      }
    });
  };

  static setupDynamicFieldButtons() {
    $('body').on('click', '.btn-destroy-added-fields', function(event) {
      event.preventDefault();
      $(event.currentTarget).closest('.added-fields').remove();
    });

    $('body').on('click', '.btn-destroy-fields', function(event) {
      event.preventDefault();
      var destroy_fields_holder = $(event.currentTarget).closest('.destroyable-fields')
      var destroy_field = destroy_fields_holder.find('input.destroy-field');
      if (destroy_field.length > 0 ) {
        destroy_field.val(1);
        destroy_fields_holder.hide();
      }
    });

    $('.btn-add-fields').on('click', function(event) {
      event.preventDefault();
      let el, content, froala_editors;
      el = $(event.target);
      // template_images_fields is a global variable defined in program
      content = el.data('fields');
      if (content.length > 0) {
        content = content.replace(new RegExp('ZZZZZ', 'g'), new Date().getTime());
        let j_content = $(content);
        let order_field = j_content.find('.order_field');
        if (order_field.length > 0) {
          // find previous order field and increase with 1
          var new_order = $('.order_field').last().val();
          if ((new_order === undefined ) || (new_order === '')) {
            new_order = 1
          } else {
            new_order = parseInt(new_order) + 1;
          }
          console.log(new_order);
          j_content.find('.order_field').val(new_order);
        }
        el.before(j_content);
        // Setup froala for fields that has froala
        froala_editors = j_content.find('.froala-minimum');
        if(froala_editors.length > 0) {
          // init_inline_froala(froala_editors);
        }
      } else {
        alert('Cannot find content to insert. Clicked on: ' + el.text() + '.');
      };
    });
  };
};