// flashes an element using css classes from flashable.
// flash-green
// flash-red
// flash-yellow
//
// Use flash-background or flash-outline
// flashing background does not work for elements with BG color

export default function flashElement(jq_element, flash_class = 'flash-green', flash_type = 'flash-outline') {
  jq_element.attr('class', `${flash_class} ${flash_type} flashable-element ${jq_element.attr('class')}`)
  let duration = jq_element.css('animation-duration');
  setTimeout(function() { jq_element.removeClass(`flashable-element ${flash_type} ${flash_class}`);}, parseFloat(duration)*1000);
}