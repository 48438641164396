// import 'shims/string-includes';
// import 'humps';

import Rails from '@rails/ujs';
import SweetAlert2Confirm from 'application/sweetalert2-confirm';

Rails.delegate(document, 'a[data-confirm]', 'click', SweetAlert2Confirm.handleConfirm)
Rails.delegate(document, 'button[data-confirm]', 'click', SweetAlert2Confirm.handleConfirm)
Rails.delegate(document, 'input[data-confirm]', 'click', SweetAlert2Confirm.handleConfirm)

// import { Tooltip, Toast, Popover } from 'bootstrap';

//= require underscore
// Underscore.js has some nifty tools
// import 'underscore'
// import 'bootstrap/js/src/button'
// import 'bootstrap/js/src/collapse'
// import 'bootstrap/js/src/modal'
// import 'bootstrap/js/src/alert'
// import 'bootstrap/js/src/tooltip'
// import 'bootstrap/js/src/popover'
// import 'bootstrap/js/src/dropdown';

// Enable 'buttons' for notifications for admins:
//= require pnotify/pnotify.buttons

// Change to https://amsik.github.io/liquor-tree
// For trees
//= require admin/nm-fancy-trees
import { RemoteDelete } from 'remote_delete'
import { RemoteForm } from 'remote_form';
import { IntranetTools } from 'tools';
import { ShowDate }  from 'show_date';
import { Froalaable } from 'froalaable';
import { Template } from 'template';
import { Bootstrap5 } from 'bootstrap5';

document.addEventListener('DOMContentLoaded', function() {
  // console.log('Initializing js (ADMIN) (doc ready)');
  RemoteDelete.setup();
  RemoteForm.setup();
  IntranetTools.setup();
  ShowDate.start();
  Template.setup();
  Bootstrap5.setup();
  // Add if using Turbolinks
  // PNotify.reload(window);
  // console.log('Done initializing (ADMIN) (doc ready)');

  // Prevent default on links with 'prevent-link'

  Array.from(document.querySelectorAll('.froalaable')).forEach(function(el, index) {
    new Froalaable(el, el.getAttribute('data-editortype'));
  });

  document.querySelectorAll('.prevent-link').forEach(function(el) {
    el.addEventListener('click', function(event) {
      event.preventDefault();
    });
  });
});

Rails.start();
