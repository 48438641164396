//
// Notes on remote form
// remote-form-flash-element must be same as remote-form-view
// remote-form-edit and remote-form-view must be siblings in the hierarchy
// This can be improved if needed
//
import flashElement from 'flash_element';
import PNotify from 'pnotify/lib/es/PNotify.js';
import jBox from 'jbox/dist/jBox'
PNotify.defaults.styling  = 'bootstrap4';

// This should be rewritten as STI or static class methods
export class RemoteForm {
  static setup() {
    if ($('.remote-form').length > 0) {
      window._remote_form = new RemoteForm();
    }
  }

  constructor() {
    this.jboxes = {};
    this.edit_jboxes = {};
    // window._remote_form = this;
    this.removeEventListeners();
    this.setupRemoteCreateFormJboxes();
    this.setupEventListeners();
  }

  destructor() {
    this.removeEventListeners();
    // window._remote_form = null;
  }

  success(event) {
    if ($(event.currentTarget).hasClass('remote-form-create')) {
      this.create(event.detail[0], $(event.target));
      if (this.jbox != null) {
        this.jbox.close();
      }
    } else {
      this.update(event.detail[0], $(event.target));
    }
  }

  create(data, target) {
    // render template
    let new_content = data.html;
    // find holder
    this.findRemoteFormDestination(target).prepend(new_content);
  }

  // jq_wrapped_content(content) {
  //   if ($(content)[0].nodeName.toLowerCase() == 'tr') {
  //     return $(`<tbody>${content}</tbody>`);
  //   } else if ($(content)[0].nodeName.toLowerCase() == 'tbody') {
  //     return $(`<table>${content}</table>`);
  //   }
  //   return $(`<div>${content}</div>`)
  // }

  findRemoteFormDestination(target){
    // Hack: This makes it impossible to use two remote forms on same page:
    let jb = target.closest('.jBox-wrapper').data().jBox;
    if (jb === undefined) {
      return $('.remote-form-create-destination');
    }
    if (jb.target.closest('.remote-form-create-destination').length > 0) {
      return jb.target.closest('.remote-form-create-destination');
    }
    return jb.target.closest('.remote-form-create-destination-outer-holder').find('.remote-form-create-destination');
  }

  update(data, target) {
    let view = this.getViewFromBuddy(target);
    if ((view === undefined) || (view === null )) {
      view = this.getFlashElement(target);
    }

    if(this.getJboxFromBuddy(target) !== undefined) {
      this.getJboxFromBuddy(target).close();
    }
    let new_view = $(data.html);
    view.parent().replaceWith(new_view);

    flashElement(new_view, 'flash-green');
  }

  error ( event ) {
    if (this.getFlashElement($(event.target)) != null) {
      flashElement(this.getFlashElement($(event.target)), 'flash-red')
    }
    console.log('RemoteForm error: cannot update! Debug info:')
    console.log(event);
    var error_text = event.detail[2].responseText;
    if (error_text.split('\n').length > 1){
      error_text = error_text.split('\n').slice(0, 20).join('\n');
    }
    PNotify.error({
      title:       'Kan ikke oppdatere',
      text:        `<pre>${error_text}</pre>`,
      textTrusted: true,
      icon:        'far fa-exclamation-triangle',
      delay:       4000,
      width:       '600px'
    });
  }

  getFlashElement(form_target) {
    let ret = null;
    let closest_flash_el;
    if (form_target.hasClass('remote-form-flash-element')) {
      ret = form_target;
    }
    if (form_target.find('.remote-form-flash-element').length > 0) {
      ret = form_target.find('.remote-form-flash-element');
    }
    closest_flash_el = form_target.closest('.remote-form-flash-element');
    if (closest_flash_el.length > 0 ) {
      if (closest_flash_el.hasClass('remote-form-edit')) {
        ret = this.getViewFromBuddy(closest_flash_el);
      } else {
        ret = form_target.closest('.remote-form-flash-element');
      }
    }
    return ret;
  }

  setEditMode(event) {
    event.preventDefault();
    let edit_holder = this.getEditHolder(event.target);
    if (edit_holder.hasClass('remote-form-edit-jbox')) {
      this.showEditJbox(edit_holder, event.target);
    } else {
      edit_holder.removeClass('d-none');
      this.getViewHolder(event.target).addClass('d-none');
    }
  }

  setViewMode(event) {
    event.preventDefault();
    var edit_holder = this.getEditHolder(event.target);
    edit_holder.addClass('d-none');
    this.getViewHolder(event.target).removeClass('d-none');
  }

  cancelEditMode(event) {
    event.preventDefault();
    var edit_holder = this.getEditHolder(event.target);
    edit_holder.addClass('d-none');
    this.getViewHolder(event.target).removeClass('d-none');
  }

  getViewFromBuddy(buddy) {
    let id = buddy.data('remoteFormId');
    return $(`.remote-form-view[data-remote-form-id=${id}]`);
  }

  getEditFromViewBuddy(view_buddy) {
    let id = view_buddy.data('remoteFormId');
    return $(`.remote-form-edit[data-remote-form-id=${id}]`);
  }

  getJboxFromBuddy(buddy){
    return this.edit_jboxes[buddy.attr('data-remote-form-id')]
  }

  removeJboxFromBuddy(buddy) {
    if (this.edit_jboxes[buddy.attr('data-remote-form-id')] !== undefined) {
      delete this.edit_jboxes[buddy.attr('data-remote-form-id')];
    }
  }

  updateviewAttributes(view, response) {
    view.find('.remote-form-view-col').each(function(index, el) {
      $(el).html(response[$(el).data('remoteFormCol')]);
    });
  }

  showEditJbox(edit_holder, button_target) {
    let edit_jbox;
    if (this.edit_jboxes[edit_holder.attr('data-remote-form-id')] !== undefined){
      edit_jbox = this.edit_jboxes[edit_holder.attr('data-remote-form-id')];
    } else {
      edit_jbox = new jBox( 'Tooltip', {
        theme:    'TooltipBorder',
        content:  $($(edit_holder).get(0).outerHTML).removeClass('d-none'),
        position: {
          x: 'center',
          y: 'bottom'
        },

        closeOnClick: 'body',
        closeButton:  'box',
        animation:    'move',

        width:   700,
        outside: 'y',
        trigger: 'click',
        // pointer: 'bottom:25',

        adjustPosition: true,
        adjustTracker:  true,
        adjustDistance: {top: 55, right: 5, bottom: 5, left: 5},

        target: this.getViewFromBuddy(edit_holder),

        // onOpen: $.proxy(this.openCreateJbox, this),
        // onOpen: function(remote_form) {
        //   console.log(remote_form);
        //   console.log(this);
        //   // $.proxy(this.openCreateJbox, this)
        // },
        // onCreated: $.proxy(this.createdCreateJbox, this),
        // onClose:   $.proxy(this.closeCreateJbox, this),

        zIndex: 8000
      });
    }
    this.edit_jboxes[edit_holder.attr('data-remote-form-id')] = edit_jbox;
    edit_jbox.open();
    return edit_jbox;
  }

  // This assumes that the view is placed before the editor in the dom
  // e.g.
  // <div class='remote-form-view'>
  //     view + edit button
  // </div>
  // <div class='remote-form-edit'>
  //     form + save button
  // </div>
  getViewHolder(click_target, check_edit_holder = true) {
    let ret;
    if ($(event.target).hasClass('remote-form-view') === true){
      return $(event.target);
    }
    ret = $(event.target).closest('.remote-form-view');
    if (ret.length > 0) {
      return ret;
    }
    // if not, get edit, and look for previous sibling
    if (check_edit_holder === true) {
      ret = this.getEditHolder(click_target, false);
      if (ret.length > 0) {
        return this.getViewFromBuddy($(ret[0]));
      }
    };
    if (ret.length == 0) {
      console.log('Remote form: Cannot find remote-form-view!');
      ret = [];
    }
    return ret;
  }

  getEditHolder(click_target, check_view_holder = true) {
    let ret;
    if ($(event.target).hasClass('remote-form-edit') === true){
      return $(event.target);
    }
    // see if it can be found up from btn
    ret = $(event.target).closest('.remote-form-edit');
    if (ret.length > 0) {
      return ret;
    }
    // if not, get view, and look for next/first sibling
    if (check_view_holder === true) {
      ret = this.getViewHolder(click_target, false);
      if (ret.length > 0) {
        return this.getEditFromViewBuddy($(ret[0]));
      }
    }
    if (ret.length == 0) {
      console.log('Remote form: Cannot find remote-form-edit!');
      ret = [];
    }
    return ret;
  }

  removeEventListeners() {
    $(document).off('ajax:success', '.remote-form');
    $(document).off('ajax:error', '.remote-form');
    $(document).off('click', '.remote-form-edit-btn');
    $(document).off('click', '.remote-form-save-btn');
    $(document).off('turbolinks:before-cache', $.proxy(this.destructor, this));
  }

  setupEventListeners() {
    // enable
    $(document).on('ajax:success', '.remote-form', $.proxy(this.success, this));
    $(document).on('ajax:error', '.remote-form', $.proxy(this.error, this));
    $(document).on('click', '.remote-form-cancel-edit-btn', $.proxy(this.cancelEditMode, this));
    $(document).on('click', '.remote-form-edit-btn', $.proxy(this.setEditMode, this));
    // $(document).on('click', '.remote-form-save-btn', $.proxy(this.saveAndCloseEditMode, this));
    $(document).on('turbolinks:before-cache', $.proxy(this.destructor, this));
  }

  setupRemoteCreateFormJboxes() {
    if ($('.remote-form-create-holder').length == 0) {
      return;
    }

    let that = this;
    $('.remote-form-create-holder').each(function (_index, el) {
      that.jboxes[$(el).attr('id')] = new jBox('Tooltip', {
        theme: 'TooltipBorder',
        content: $(el).html(),
        position: {
          x: 'left',
          y: 'bottom'
        },

        closeOnClick: 'body',
        closeButton: 'box',
        animation: 'flip',

        width: 700,
        outside: 'y',
        offset: {x: 18},
        trigger: 'click',
        // pointer: 'left:500',

        adjustPosition: true,
        adjustTracker: true,
        adjustDistance: { top: 55, right: 5, bottom: 5, left: 20 },

        attach: $('.' + $(el).attr('id').replace('holder', 'btn')),

        onOpen: $.proxy(that.openCreateJbox, that, this),

        // onOpen: function(remote_form) {
        //   console.log(remote_form);
        //   console.log(this);
        //   // $.proxy(this.openCreateJbox, this)
        // },
        // onCreated: $.proxy(this.createdCreateJbox, this),
        // onClose:   $.proxy(this.closeCreateJbox, this),

        zIndex: 8000
      });
    });
  }

  openCreateJbox(jbox_content_holder){
    // this.setCreateDataFromTarget(this.jbox.target, this.jbox.content.find('form.remote-form-create'));
    // Hack to focus on input
    let jbox_content_holder_id = $(jbox_content_holder).attr('id');
    let that = this;
    setTimeout(() => {
      that.jboxes[jbox_content_holder_id].content.find('form.remote-form-create :input:enabled:visible:first').first().focus();
    }, 500);

  }

  setCreateDataFromTarget(target, remote_form_create) {
    if (target.data('remoteFormCreate') !== undefined) {
      remote_form_create.find('input').each(function(index, el) {
        if (target.data('remoteFormCreate')[el.id] !== undefined) {
          // console.log(`Remote Form: Setting ${el.id} to ${target.data('remoteFormCreate')[el.id]}`);
          $(el).val(target.data('remoteFormCreate')[el.id]);
        }
      });
    }
  }
}

