/* global PNotify */
import flashElement from 'flash_element';
import PNotify from 'pnotify/lib/es/PNotify.js';
PNotify.defaults.styling  = 'bootstrap4';

// This should be rewritten as STI or static class methods
export class RemoteDelete {
  static setup() {
    if ($('.remote-delete').length > 0) {
      new RemoteDelete();
    }
  }

  constructor() {
    this.removeEventListeners();
    this.setupEventListeners();
  }

  success( event ) {
    PNotify.info({
      title: 'Slettet kommentar',
    });
    this.getFlashElement($(event.target)).hide(450, function(e) {
      $(this).remove();
    })
  }

  error ( xhr, status, error ) {
    flashElement(this.getFlashElement($(event.target)), 'flash-red')
    PNotify.error({
      title: 'Cannot remove element',
      text:  'Debug info on the console...',
    });
    console.log('RemoteDelete error: Debug info:')
    console.log(event);
  }

  getFlashElement(delete_target) {
    if (delete_target.hasClass('remote-delete-flash-element')) {
      return delete_target;
    }
    if (delete_target.find('.remote-delete-flash-element').length > 0) {
      return delete_target.find('.remote-delete-flash-element');
    }
    if (delete_target.closest('.remote-delete-flash-element').length > 0 ) {
      return delete_target.closest('.remote-delete-flash-element')
    }
  }

  removeEventListeners() {
    $(document).off('ajax:success', '.remote-delete');
    $(document).off('ajax:error', '.remote-delete');
  }

  setupEventListeners() {
    $(document).on('ajax:success', '.remote-delete', $.proxy(this.success, this));
    $(document).on('ajax:error', '.remote-delete', $.proxy(this.error, this));
  }
}
